import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter,NgZone } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'; 
import { AppService } from '../../app.service'; 
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss']
})
export class PropertiesSearchComponent implements OnInit {
  @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
  @Input() variant:number = 1;
  @Input() vertical:boolean = false;
  @Input() searchOnBtnClick:boolean = false;
  @Input() removedSearchField:string;
  @Input() countrieslist:any;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();

  public showMore: boolean = false;
  public form: UntypedFormGroup;
  public propertyTypes :any = [];
  public propertyCategory :any = [];
  public propertyStatuses :any = [];
  public spokenlanguges:any=[];
  public countries :any = [];
  public handicap :any = [];
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features :any = [];
  public lat: any;
  public lng: any;
  public urlactivated:any;
  public mapsearch:boolean = false;
  public location;
  public showbuyprice:boolean=false;
  public MlsStatus :any = [];
  public search_values;
  public selectedCount: number = 0;
  public selectedValues: string[] = [];
  isStatusChecked: boolean= true;

  constructor(public appService:AppService,
              public router:Router,
               private mapsAPILoader: MapsAPILoader,
               private ngZone: NgZone,
               public fb: UntypedFormBuilder
              ) {}

  ngOnInit() {
    this.search_values = JSON.parse(localStorage.getItem('filtervaluessave'));
    console.warn('home values',this.search_values?.propertyStatus)
    if(this.search_values?.propertyStatus==2)
      this.isStatusChecked = false;
    else
    this.isStatusChecked = true;
    this.form = this.fb.group({
      propertyType: null,
      propertyStatus: this.search_values?.propertyStatus?this.search_values.propertyStatus:1,
      propertyCategory: null,
      country:null,
      mlsStatus:1,
      handicap: "false",
      unit:"true",
      location:[JSON.parse(localStorage.getItem('location')),Validators.required],
      lat:null,
      lng:null, 
      price: this.fb.group({
        from: "",
        to:"" 
      }),
      myChoices: new UntypedFormArray([]),
      property_features: new UntypedFormArray([]),
      property_view: new UntypedFormArray([]),
      onlyshowagnet: new UntypedFormArray([]),
      spokenlanguge: new UntypedFormArray([]),
      pg:this.search_values?.pg?this.search_values.pg:null,
      searchbykeyword:null,
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: "" 
      }),
      bathrooms: this.fb.group({
        from: null,
        to: "" 
      }),
      garages: this.fb.group({
        from: null,
        to:""  
      }),
      area: this.fb.group({
        from:"",
        to: "" 
      }),
      sq_m:this.fb.group({
        from:"",
        to:""
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: null  
      }),       
      features: this.buildFeatures()
    }); 
     this.urlactivated = this.router.isActive('/',true)

     this.mapsearch = this.router.isActive('/mapsearch',true)

     
   // console.warn('this is seasrch init: ', this.urlactivated);
    if(this.vertical){
      this.showMore = true;
    };

    if(!this.urlactivated)
    {
    this.propertyTypes = this.appService.getPropertyTypes();
    this.propertyStatuses = this.appService.getPropertyStatuses();
    this.propertyCategory = this.appService.getPropertyCategory();
    this.spokenlanguges = this.appService.getSpokenLanguages(true);
    this.countries = this.appService.getCountry();
    this.handicap = this.appService.getHandiCap();
    this.cities = this.appService.getCities();
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();


    this.MlsStatus = this.appService.getStatusMls();
  
  }

      //this.form = JSON.parse(localStorage.getItem('filtervaluessave'));

     

      //console.warn("this is sarch feild", this.search_values);
      //alert(this.search_values.propertyStatus);

     

    this.onSearchChange.emit(this.form);

    this.placesAutocomplete();
  }

  setFilterValues(): void {
    const formValues = this.form.value; // Get form values
    // Do something with formValues, e.g., store in a variable
    // For example:
    localStorage.setItem('filtervaluessave', JSON.stringify(formValues));
    // Navigate to the mapsearch route
    this.router.navigate(['/mapsearch']);
  }
  public buildFeatures() {
    const arr = this.features.map(feature => { 
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected
      });
    })   
    return this.fb.array(arr);
  }
  
  getcountryproperties(id,name)
  {     
    this.setFilterValues()
    localStorage.setItem('search_country_id',id);
    localStorage.removeItem('location');
    const encodedName = encodeURIComponent(name).replace(/%20/g, ''); // Removes spaces
    this.router.navigateByUrl(`/${encodedName}/properties`);
      // this.appService.getLatLng(name).subscribe(res=>{
      //   console.log('here res',res);
        
      //   this.lat =  res['results'][0].geometry.location.lat;
      //   this.lng =  res['results'][0].geometry.location.lng;
      //   this.location = name;
      //   localStorage.setItem('currnet_latitude', JSON.stringify(this.lat));
      //   localStorage.setItem('currnet_longitude', JSON.stringify(this.lng));
      //   // localStorage.setItem('location', JSON.stringify(name));
       
      // })
  }
  ngOnChanges(){ 

    if(this.removedSearchField){ 
      if(this.removedSearchField.indexOf(".") > -1){
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      } 
      else if(this.removedSearchField.indexOf(",") > -1){        
        let arr = this.removedSearchField.split(","); 
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);  
      }
      else{
        this.form.controls[this.removedSearchField].reset();
      }  
    }  
  }

  public reset(){     
    this.form.reset({ 
      propertyType: null,
     
      propertyCategory: null,
      country:null,
      handicap: "false",
      mlsStatus:null,
 
      lat:null,
      lng:null, 
      
      myChoices: new UntypedFormArray([]),
      property_features: new UntypedFormArray([]),
      property_view: new UntypedFormArray([]),
      onlyshowagnet: new UntypedFormArray([]),
      spokenlanguge: new UntypedFormArray([]),
      pg:"",
      searchbykeyword:null,

      price: {
        from: null,
        to: null 
      },
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      bedrooms: {
        from: null,
        to: null 
      },
      bathrooms: {
        from: null,
        to: null 
      },
      garages: {
        from: null,
        to: null 
      },
      area: {
        from: null,
        to: null 
      },
      yearBuilt: {
        from: null,
        to: null 
      },       
      features: this.features    
    }); 
  }

  public search(){
    this.onSearchClick.emit(); 
  }

  public onSelectCity(){
    this.form.controls['neighborhood'].setValue(null, {emitEvent: false});
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }
  public onSelectNeighborhood(){
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }

  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }

  private placesAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, {
        types: ["geocode"]
      });
      console.warn('address main ', autocomplete);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          localStorage.setItem('currnet_latitude', JSON.stringify(this.lat));
          localStorage.setItem('currnet_longitude', JSON.stringify(this.lng));
          let postalCode:any;
          let for_address:any;
          for (const component of place.address_components) {
            if (component.types.includes('postal_code')) {
              postalCode = component.short_name;
              break;
            }
          }
          let firstFive = place.formatted_address.substring(0, 5);
          if(postalCode== firstFive)
          for_address = place.formatted_address.replace(/\d{5}/, '');
          else
          for_address = place.formatted_address;
          localStorage.setItem('location', JSON.stringify(for_address));
          this.form.controls['lat'].setValue(this.lat);
          this.form.controls['lng'].setValue(this.lng);
          this.form.controls['location'].setValue(for_address);
        });
      });
    });
  }
  //==========================================old========================================================
  // private placesAutocomplete(){  
  //   this.mapsAPILoader.load().then(() => { 
  //     let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, {
  //       types: ["geocode"]
  //     });  
  //     autocomplete.addListener("place_changed", () => { 
  //       this.ngZone.run(() => { 
  //         let place: google.maps.places.PlaceResult = autocomplete.getPlace(); 
  //         if (place.geometry === undefined || place.geometry === null) {
  //           return;
  //         };
  //         this.lat = place.geometry.location.lat();
  //         this.lng = place.geometry.location.lng();
  //         localStorage.setItem('currnet_latitude', JSON.stringify(this.lat));
  //         localStorage.setItem('currnet_longitude', JSON.stringify(this.lng));
  //         let for_address = place.formatted_address.replace(/\d{5}/, '');
  //         localStorage.setItem('location', JSON.stringify(for_address));

  //         this.form.controls['lat'].setValue(this.lat);
  //         this.form.controls['lng'].setValue(this.lng);  
  //         this.form.controls['location'].setValue(for_address);  
  //         console.warn('this is get place:',this.lat);
  //       });
  //     });
  //   });
  // }
  //==========================================oldEnd========================================================
  public getAddress(){    
    this.appService.getAddress(this.lat, this.lng).subscribe(response => {  
      console.log(response);
      if(response['results'].length){
        let address = response['results'][0].formatted_address; 
        //this.setAddresses(response['results'][0]); 
      } 
    })
  }

  public statuschange(value)
  {
    if(value == 1)
    this.showbuyprice =true;
    else
    this.showbuyprice =false;
  }



  checkCheckBoxvalue(event,column) {
    const formArray: UntypedFormArray = this.form.get(column) as UntypedFormArray;
  
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new UntypedFormControl(event.target.value));
      this.selectedValues.push(event.target.value);
      this.selectedCount++;
      alert(this.selectedCount)
    }
    /* unselected */
    else{
      // find the unselected element
      const index = this.selectedValues.indexOf(event.target.value);
    if (index > -1) {
      this.selectedValues.splice(index, 1);
    }
      let i: number = 0;
      this.selectedCount--;
      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
  
        i++;
      });
    }
  }

 

}
