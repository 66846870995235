import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Property, Response, Location } from './app.models';
import { AppSettings } from './app.settings';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export class Data {
  constructor(public properties: Property[],
    public compareList: Property[],
    public favorites: Property[],
    public locations: Location[]) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [], // properties
    [], // compareList
    [], // favorites
    []  // locations
  )

  //#region component communication
  messageSubject = new Subject<any>();
  //#endregion
  public url = environment.url + '/assets/data/';
  // public apiKey = 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I';
  private translationApiUrl = 'https://translation.googleapis.com/language/translate/v2';
  public apiurl = "https://realknu.com/backend/";
  public apiKey = 'AIzaSyD8EMcNdznIo0nEQygu9QPuTvL6Ppe4j_M';
  //private MAP_BASE_URL = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json';


  public resvalue: any;;

  userlogin = new Subject<boolean>();


  constructor(public http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    public appSettings: AppSettings,
    public dialog: MatDialog,
    public translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  public translateText(text: string, targetLanguage: string): Observable<any> {
    const params = {
      q: text,
      target: targetLanguage,
      key: this.apiKey,
    };

    return this.http.post(this.translationApiUrl, null, { params });
  }

  public addproperty(property) {
    return this.http.post<any>(this.apiurl + `api/WebApi/addproperty`, property)
  }
  ////////////////////////////////////
  // public getSchools(latitude: number, longitude: number): Observable<any> {

  //   const params = {
  //     location: `${latitude},${longitude}`,
  //     radius: '10000', // Radius in meters
  //     type: 'school',
  //     key: this.apiKey
  //   };


  //   return this.http.get(this.MAP_BASE_URL, { params });
  // }


  public getressearch(e) {
    //this.resvalue=e;
    alert(e)
    this.messageSubject.next(e);
    //   return this.resvalue;
  }

  public getpolygon(location) {
    let values = [];
    this.http.get<any>('https://nominatim.openstreetmap.org/search.php?q=' + location + '&polygon_geojson=1&format=json').subscribe(res => {
      res[0].geojson.coordinates[0].forEach(item => {
        values.push({ 'lat': item[1], 'lng': item[0] })
      });
    });
    return values;
  }

  public getjsonpolygon() {
    let values = [];
    this.http.get<any>(this.url + 'polygon.json').subscribe(res => {
      res.forEach(item => {
        values.push({ 'lat': item[1], 'lng': item[0] })
      });
    });


    return values;

  }

  public login(username: string, password: string) {
    //console.warn({email_id: username, password: password});
    return this.http.post<any>(this.apiurl + `api/Api/signinuser`, { email_id: username, password: password })
  }

  public getcountries() {
    return this.http.get<any>(this.apiurl + 'api/WebApi/getcountries_search/');
  }
  public getUserById(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiurl}api/WebApi/getUserById?userId=${userId}`);
  }
  public editprofile(senddata) {
    return this.http.post<any>(this.apiurl + 'api/Api/edit_profile_agent/', senddata);
  }
  public changePassword(senddata) {
    console.warn('appservice', senddata);

    return this.http.post<any>(this.apiurl + 'api/WebApi/change_password/', senddata);
  }
  public submitcontact(senddata) {
    return this.http.post<any>(this.apiurl + 'api/WebApi/sendemail/', senddata);
  }

  public getProperties(senddata?): Observable<any> {
    return this.http.post<any>(this.apiurl + 'api/WebApi/propertiesformap/', senddata);
  }


  public getcampareProperties(senddata?): Observable<any> {
    // senddata = this.obj_to_query(senddata);

    return this.http.post<any>(this.apiurl + 'api/WebApi/camparelisting/', senddata);
  }
  public getcampareUnits(senddata?): Observable<any> {
    // senddata = this.obj_to_query(senddata);

    return this.http.post<any>(this.apiurl + 'api/WebApi/campareListingUnits/', senddata);
  }

  public getListingProperties(senddata?): Observable<any> {
    senddata = this.obj_to_query(senddata);
    return this.http.get<any>(this.apiurl + 'api/WebApi/properties_list' + senddata);
  }
  public getListingByCountry(senddata?): Observable<any> {
    senddata = this.obj_to_query(senddata);
    console.warn('service', senddata);

    return this.http.get<any>(this.apiurl + 'api/WebApi/listing_by_country' + senddata);
  }

  public getagentproperties(senddata): Observable<any> {
    return this.http.post<any>(this.apiurl + 'api/WebApi/agentproperties/', senddata);
  }
  public getdocuments(senddata): Observable<any> {
    return this.http.post<any>(this.apiurl + 'api/Api/document_list/', senddata);
  }


  // public deleteproperty(id) {
  //   return this.http.get<any>(this.apiurl + 'api/WebApi/delete_property/' + id);
  // }

  public deleteproperty(senddata?): Observable<any> {
    return this.http.post<any>(this.apiurl + 'api/WebApi/delete_property/', senddata);
  }

  public getSubAgents(senddata?): Observable<any> {
    return this.http.post<any>(this.apiurl + 'api/WebApi/getsubagent/', senddata);
  }

  public getPropertyById(id): Observable<any> {
    return this.http.get<Property>(this.apiurl + 'api/WebApi/property_details/' + id[0] + '/' + id[1]);
    //return this.http.get<Property>(this.url + 'property-' + id + '.json');
  }

  public getagentById(id): Observable<any> {
    return this.http.get<any>(this.apiurl + 'api/WebApi/agentbyid/' + id);
    //return this.http.get<Property>(this.url + 'property-' + id + '.json');
  }

  public getallads(data): Observable<any> {

    return this.http.post<any>(this.apiurl + `api/WebApi/getallads`, data);

    // return this.http.get<Property>(this.apiurl + 'api/WebApi/getallads/' + id);
  }

  public getchildresource(id): Observable<any> {
    return this.http.get<Property>(this.apiurl + 'api/WebApi/get_child/' + id);
  }

  public getresource(): Observable<any> {
    return this.http.get<Property>(this.apiurl + 'api/WebApi/ad_type/?lang=' + this.translateService.currentLang);
  }

  public getpropertyforedit(id): Observable<any> {
    return this.http.get<Property>(this.apiurl + 'api/WebApi/getpropertyedit/' + id);
  }

  public getFeaturedProperties(): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'featured-properties.json');
  }

  public getRelatedProperties(): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'related-properties.json');
  }

  public getPropertiesByAgentId(agentId): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'properties-agentid-' + agentId + '.json');
  }

  public getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.url + 'locations.json');
  }

  public getAddress(lat = 40.714224, lng = -73.961452) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.apiKey);
  }

  public getLatLng(address) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + this.apiKey + '&address=' + address);
  }

  public getFullAddress(lat = 40.714224, lng = -73.961452) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.apiKey).subscribe(data => {
      return data['results'][0]['formatted_address'];
    });
  }

  public addToCompare(property: Property, component, direction) {
    if (!this.Data.compareList.filter(item => item.id == property.id)[0]) {
      this.Data.compareList.push(property);
      this.bottomSheet.open(component, {
        direction: direction
      }).afterDismissed().subscribe(isRedirect => {
        if (isRedirect) {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        }
      });
    }
  }

  public addToFavorites(property: Property, direction) {
    console.warn('fave data from home', property);

    if (!this.Data.favorites.filter(item => item.id == property.id)[0]) {
      this.Data.favorites.push(property);
      this.snackBar.open('The property "' + property.title + '" has been added to favorites.', '×', {
        verticalPosition: 'top',
        duration: 3000,
        direction: direction
      });
    }
  }

  public openConfirmDialog(title: string, message: string) {
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    return dialogRef;
  }

  public openAlertDialog(message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message
    });
    return dialogRef;
  }

  public getTranslateValue(key: string, param: string = null) {
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;

    })
    return value;
  }



  public getTranslateValue2(key: string, param: string = null) {

    return new Promise((resolve, reject) => {
      setTimeout(() => {

        this.translateService.get(key, { param: param }).subscribe((res: string) => {
          resolve(res);
        })

      }, 1000);
    });



    // return value; 
  }

  public getPropertyTypes() {
    let values = [];

    this.http.get<any>(this.apiurl + 'api/WebApi/getPropertyTypes?lang=' + this.translateService.currentLang).subscribe(res => {

      res.result.forEach(item => {
        values.push({ 'id': item.id, 'name': item.name })
      });
    });
    return values;
    // return value;
    //console.warn('property type:',values);
    // return [ 
    //   { id: 1, name: 'Office' },
    //   { id: 2, name: 'House' },
    //   { id: 3, name: 'Apartment' }
    // ]
  }
  public getPropertyCategory() {
    let values = [];
    this.http.get<any>(this.apiurl + 'api/WebApi/propertycategory?lang=' + this.translateService.currentLang).subscribe(res => {

      res.result.forEach(item => {
        if (item.name != 'Rental')
          values.push({ 'id': item.id, 'name': item.name })
      });
    });
    return values;
    //console.warn('here',values);

    // return [ 
    //   { id: 1, name: 'Residencial' },
    //   { id: 2, name: 'Commercial' },
    // ]
  }

  public getPropertyStatuses() {
    //this.translateService.currentLang;
    let values = [];
    this.http.get<any>(this.apiurl + 'api/WebApi/getPropertyStatuses?lang=' + this.translateService.currentLang).subscribe(res => {

      res.result.forEach(item => {
        values.push({ 'id': item.id, 'name': item.name })
      });
    });
    return values;
    // return [ 
    //   { id: 1, name: 'For Sale' },
    //   { id: 2, name: 'For Rent' }
    //   // { id: 3, name: 'Open House' },
    //   // { id: 4, name: 'No Fees' },
    //   // { id: 5, name: 'Hot Offer' },
    //   // { id: 6, name: 'Sold' }
    // ]
  }

  public getHandiCap() {
    return [
      { id: 1, name: 'Yes' },
      { id: 0, name: 'No' },
    ]
  }

  // public getCities(){
  //   return [ 
  //     { id: 1, name: 'New York' },
  //     { id: 2, name: 'Chicago' },
  //     { id: 3, name: 'Los Angeles' },
  //     { id: 4, name: 'Seattle' } 
  //   ]
  // }
  public getSpokenLanguages(forlisting = false) {

    let values = [];
    this.http.get<any>(this.apiurl + 'api/WebApi/getspokenlanguges').subscribe(res => {

      if (forlisting) {
        res.result.forEach(item => {
          values.push({ 'id': item.id, 'name': item.name })
        });
      }
      else {
        res.result.forEach(item => {
          values.push({ 'id': item.id, 'name': item.name, 'selected': false })
        });

      }

    });
    return values;


    // return [ 
    //   { id: 1, name: 'New York' , selected: false },
    //   { id: 2, name: 'Chicago' , selected: false },
    //   { id: 3, name: 'Los Angeles' , selected: false},
    //   { id: 4, name: 'Seattle' , selected: false} 
    // ]
  }
  public getCountry() {

    let values = [];
    this.http.get<any>(this.apiurl + 'api/WebApi/countrylist').subscribe(res => {

      res.result.forEach(item => {
        values.push({ 'id': item.id, 'name': item.name, 'countrycode': item.phonecode, 'selected': false })
      });
    });
    return values;
  }
  public getcurrenccy(countryid) {
    return this.http.get<any>(this.apiurl + 'api/WebApi/getcurrency/' + countryid);
  }
  public getState(countryid) {

    let values = [];
    this.http.get<any>(this.apiurl + 'api/WebApi/getStateByCountry/' + countryid).subscribe(res => {
      res.result.forEach(item => {
        values.push({ 'id': item.id, 'name': item.name, 'selected': false })
      });
    });
    return values;


  }

  public getCities(countryid?) {

    let values = [];
    this.http.get<any>(this.apiurl + 'api/WebApi/getCitiesByState/' + countryid).subscribe(res => {
      res.result.forEach(item => {
        values.push({ 'id': item.id, 'name': item.name, 'selected': false })
      });
    });
    return values;


  }

  public getNeighborhoods() {
    return [
      { id: 1, name: 'Astoria', cityId: 1 },
      { id: 2, name: 'Midtown', cityId: 1 },
      { id: 3, name: 'Chinatown', cityId: 1 },
      { id: 4, name: 'Austin', cityId: 2 },
      { id: 5, name: 'Englewood', cityId: 2 },
      { id: 6, name: 'Riverdale', cityId: 2 },
      { id: 7, name: 'Hollywood', cityId: 3 },
      { id: 8, name: 'Sherman Oaks', cityId: 3 },
      { id: 9, name: 'Highland Park', cityId: 3 },
      { id: 10, name: 'Belltown', cityId: 4 },
      { id: 11, name: 'Queen Anne', cityId: 4 },
      { id: 12, name: 'Green Lake', cityId: 4 }
    ]
  }


  public getStatusMls() {
    return [
      { id: 1, name: 'Active' },
      { id: 3, name: 'Active With Contract' },
      { id: 5, name: 'Sold' }
    ]
  }

  public getStreets() {
    return [
      { id: 1, name: 'Astoria Street #1', cityId: 1, neighborhoodId: 1 },
      { id: 2, name: 'Astoria Street #2', cityId: 1, neighborhoodId: 1 },
      { id: 3, name: 'Midtown Street #1', cityId: 1, neighborhoodId: 2 },
      { id: 4, name: 'Midtown Street #2', cityId: 1, neighborhoodId: 2 },
      { id: 5, name: 'Chinatown Street #1', cityId: 1, neighborhoodId: 3 },
      { id: 6, name: 'Chinatown Street #2', cityId: 1, neighborhoodId: 3 },
      { id: 7, name: 'Austin Street #1', cityId: 2, neighborhoodId: 4 },
      { id: 8, name: 'Austin Street #2', cityId: 2, neighborhoodId: 4 },
      { id: 9, name: 'Englewood Street #1', cityId: 2, neighborhoodId: 5 },
      { id: 10, name: 'Englewood Street #2', cityId: 2, neighborhoodId: 5 },
      { id: 11, name: 'Riverdale Street #1', cityId: 2, neighborhoodId: 6 },
      { id: 12, name: 'Riverdale Street #2', cityId: 2, neighborhoodId: 6 },
      { id: 13, name: 'Hollywood Street #1', cityId: 3, neighborhoodId: 7 },
      { id: 14, name: 'Hollywood Street #2', cityId: 3, neighborhoodId: 7 },
      { id: 15, name: 'Sherman Oaks Street #1', cityId: 3, neighborhoodId: 8 },
      { id: 16, name: 'Sherman Oaks Street #2', cityId: 3, neighborhoodId: 8 },
      { id: 17, name: 'Highland Park Street #1', cityId: 3, neighborhoodId: 9 },
      { id: 18, name: 'Highland Park Street #2', cityId: 3, neighborhoodId: 9 },
      { id: 19, name: 'Belltown Street #1', cityId: 4, neighborhoodId: 10 },
      { id: 20, name: 'Belltown Street #2', cityId: 4, neighborhoodId: 10 },
      { id: 21, name: 'Queen Anne Street #1', cityId: 4, neighborhoodId: 11 },
      { id: 22, name: 'Queen Anne Street #2', cityId: 4, neighborhoodId: 11 },
      { id: 23, name: 'Green Lake Street #1', cityId: 4, neighborhoodId: 12 },
      { id: 24, name: 'Green Lake Street #2', cityId: 4, neighborhoodId: 12 }
    ]
  }

  public getFeatures() {

    // let values = [];
    // this.http.get<any>(this.apiurl + 'api/WebApi/getFeatures').subscribe(res=>{

    //   res.result.forEach(item => { 
    //     values.push({id:item.id,name:item.name,selected: false})
    //   }); 
    // });
    // return values;
    return [
      { id: 1, name: 'Air Conditioning', selected: false },
      { id: 2, name: 'Barbeque', selected: false },
      { id: 3, name: 'Dryer', selected: false },
      { id: 4, name: 'Microwave', selected: false },
      { id: 5, name: 'Refrigerator', selected: false },
      { id: 6, name: 'TV Cable', selected: false },
      { id: 7, name: 'Sauna', selected: false },
      { id: 8, name: 'WiFi', selected: false },
      { id: 9, name: 'Fireplace', selected: false },
      { id: 10, name: 'Swimming Pool', selected: false },
      { id: 11, name: 'Gym', selected: false },
    ]
  }


  public getHomeCarouselSlides() {
    return this.http.get<any[]>(this.url + 'slides.json');
  }


  public filterData(data, sort?, page?, perPage?) {

    //console.warn('total properties befor sort = ',data);
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }

  public sortData(sort, data) {
    if (sort) {
      switch (sort) {
        case 'Newest':
          data = data.sort((a, b) => { return <any>new Date(b.published) - <any>new Date(a.published) });
          break;
        case 'Oldest':
          data = data.sort((a, b) => { return <any>new Date(a.published) - <any>new Date(b.published) });
          break;
        case 'Popular':
          data = data.sort((a, b) => {
            if (a.ratingsValue / a.ratingsCount < b.ratingsValue / b.ratingsCount) {
              return 1;
            }
            if (a.ratingsValue / a.ratingsCount > b.ratingsValue / b.ratingsCount) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price (Low to High)':
          data = data.sort((a, b) => {
            if ((a.price) > (b.price)) {
              return 1;
            }
            if ((a.price) < (b.price)) {
              return -1;
            }
            return 0;
          })

          break;
        case 'Price (High to Low)':
          data = data.sort((a, b) => {
            if ((a.price) < (b.price)) {
              return 1;
            }
            if ((a.price) > (b.price)) {
              return -1;
            }
            return 0;
          })

          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items, page?, perPage?) {
    var page = page || 1,
      perPage = perPage || 4,
      offset = (page - 1) * perPage,
      paginatedItems = items.slice(offset).slice(0, perPage),
      totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination: {
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }

  update_subagent(formdata) {
    return this.http.post<any>(`${this.apiurl}api/Api/update_subagent_status`, formdata);
  }
  remove_user(formdata) {
    return this.http.post<any>(`${this.apiurl}api/WebApi/delete_agent`, formdata);
  }


  update_user_type(formdata) {
    return this.http.post<any>(`${this.apiurl}api/WebApi/update_user_type`, formdata);
  }



  public getTestimonials() {
    return [
      // { 
      //     text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
      //     author: 'Mr. Adam Sandler', 
      //     position: 'General Director', 
      //     image: 'assets/images/profile/adam.jpg' 
      // },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Ashley Ahlberg',
        position: 'Housewife',
        image: 'assets/images/profile/ashley.jpg'
      },
      // { 
      //     text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
      //     author: 'Bruno Vespa', 
      //     position: 'Blogger', 
      //     image: 'assets/images/profile/bruno.jpg' 
      // },
      // { 
      //     text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.', 
      //     author: 'Mrs. Julia Aniston', 
      //     position: 'Marketing Manager', 
      //     image: 'assets/images/profile/julia.jpg' 
      // }
    ];
  }

  public getAgents(formdata): Observable<any> {

    return this.http.post<any[]>(this.apiurl + 'api/WebApi/agent_list', formdata);

    // return [        
    //     { 
    //         id: 1,
    //         fullName: 'Lusia Manuel',
    //         desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
    //         organization: 'HouseKey',
    //         email: 'lusia.m@housekey.com',
    //         phone: '(224) 267-1346',
    //         social: {
    //           facebook: 'lusia',
    //           twitter: 'lusia',
    //           linkedin: 'lusia',
    //           instagram: 'lusia',
    //           website: 'https://lusia.manuel.com'
    //         },
    //         ratingsCount: 6,
    //         ratingsValue: 480,
    //         image: 'assets/images/agents/a-1.jpg' 
    //     },
    //     { 
    //         id: 2,
    //         fullName: 'Andy Warhol',
    //         desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
    //         organization: 'HouseKey',
    //         email: 'andy.w@housekey.com',
    //         phone: '(212) 457-2308',
    //         social: {
    //           facebook: '',
    //           twitter: '',
    //           linkedin: '',
    //           instagram: '',
    //           website: 'https://andy.warhol.com'
    //         },
    //         ratingsCount: 4,
    //         ratingsValue: 400,
    //         image: 'assets/images/agents/a-2.jpg' 
    //     },        
    //     { 
    //         id: 3,
    //         fullName: 'Tereza Stiles',
    //         desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
    //         organization: 'HouseKey',
    //         email: 'tereza.s@housekey.com',
    //         phone: '(214) 617-2614',
    //         social: {
    //           facebook: '',
    //           twitter: '',
    //           linkedin: '',
    //           instagram: '',
    //           website: 'https://tereza.stiles.com'
    //         },
    //         ratingsCount: 4,
    //         ratingsValue: 380,
    //         image: 'assets/images/agents/a-3.jpg' 
    //     },
    //     { 
    //       id: 4,
    //       fullName: 'Michael Blair',
    //       desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
    //       organization: 'HouseKey',
    //       email: 'michael.b@housekey.com',
    //       phone: '(267) 388-1637',
    //       social: {
    //         facebook: '',
    //         twitter: '',
    //         linkedin: '',
    //         instagram: '',
    //         website: 'https://michael.blair.com'
    //       },
    //       ratingsCount: 6,
    //       ratingsValue: 480,
    //       image: 'assets/images/agents/a-4.jpg'  
    //     },
    //     { 
    //         id: 5,
    //         fullName: 'Michelle Ormond',
    //         desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
    //         organization: 'HouseKey',
    //         email: 'michelle.o@housekey.com',
    //         phone: '(267) 388-1637',
    //         social: {
    //           facebook: '',
    //           twitter: '',
    //           linkedin: '',
    //           instagram: '',
    //           website: 'https://michelle.ormond.com'
    //         },
    //         ratingsCount: 6,
    //         ratingsValue: 480, 
    //         image: 'assets/images/agents/a-5.jpg' 
    //     }
    // ];
  }



  public getClients() {
    return [
      { name: 'aloha', image: 'assets/images/clients/aloha.png' },
      { name: 'dream', image: 'assets/images/clients/dream.png' },
      { name: 'congrats', image: 'assets/images/clients/congrats.png' },
      { name: 'best', image: 'assets/images/clients/best.png' },
      { name: 'king', image: 'assets/images/clients/king.png' },
      { name: 'love', image: 'assets/images/clients/love.png' },
      { name: 'the', image: 'assets/images/clients/the.png' },
      { name: 'easter', image: 'assets/images/clients/easter.png' },
      { name: 'bravo', image: 'assets/images/clients/bravo.png' }
    ];
  }



  public obj_to_query(obj) {

    var parts = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    }
    return "?" + parts.join('&');
  }

  public addformquery(data) {
    return this.http.post<any>(this.apiurl + `api/WebApi/home_worth`, data)
  }
  public getCoordinates() {
    // let lat:any;
    // let lng:any;
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position: any) => {
    //     if (position) {
    //       console.log("Latitude: " + position.coords.latitude +
    //         "Longitude: " + position.coords.longitude);
    //       // this.lat = position.coords.latitude;
    //       // this.lng = position.coords.longitude;
    //       // console.log(this.lat);
    //       // console.log(this.lng);
    //     }
    //   },
    //     (error: any) => console.log(error));
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }
    // return new Promise((resolve, reject) => {

    //   navigator.geolocation.getCurrentPosition(resp => {

    //       resolve({ lat: resp.coords.latitude,lng: resp.coords.longitude});
    //     },
    //     err => {
    //       reject(err);
    //     });
    // });
  }


}
