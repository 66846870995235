import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ai-search',
  templateUrl: './ai-search.component.html',
  styleUrls: ['./ai-search.component.scss']
})
export class AiSearchComponent implements OnInit {
  aiSearchForm: FormGroup;
  constructor(private fb: FormBuilder) { }
  ngOnInit(): void {
    this.aiSearchForm = this.fb.group({
      aiQueryText: ['']  // Initialize the form control for searchText
    });
  }
  onSearch() {
    const query = this.aiSearchForm.get('aiQueryText')?.value;
    console.log('Searching for:', query);
    const cityRegex = /^[A-Za-z\s]+/; // Matches city name
    const priceRegex = /from\s([\d.]+[MK]?)\sto\s([\d.]+[MK]?)/i; // Matches price range
    const typeRegex = /(homes|condos|apartments|properties)/i; // Matches property type

    const cityMatch = query.match(cityRegex);
    const priceMatch = query.match(priceRegex);
    const typeMatch = query.match(typeRegex);

    const filters = {
      city: cityMatch ? cityMatch[0].trim() : null,
      propertyType: typeMatch ? typeMatch[0].toLowerCase() : null,
      priceRange: priceMatch
        ? { min: this.convertPrice(priceMatch[1]), max: this.convertPrice(priceMatch[2]) }
        : null,
    };
    console.log('Extracted Filters:', filters);
  }
  convertPrice(price: string) {
    if (price.endsWith('M')) {
      return parseFloat(price) * 1_000_000;
    } else if (price.endsWith('K')) {
      return parseFloat(price) * 1_000;
    }
    return parseFloat(price);
  }
}
